<template>
  <div id="previewVideoContainer" class="relative">
    <video
      id="reviewVideo"
      ref="reviewVideo"
      preload="metadata"
      class="rounded-4 max-w-484px w-full h-auto"
      playsinline
      @loadedmetadata="onLoadedMetadata"
      @timeupdate="onTimeUpdate"
      @play="onPlay"
      @pause="onPause"
      @stop="onPause"
      @error="onPause"
      @ended="onEnd"
    />
  </div>

  <div class="pb-3.5 pt-18px px-6">
    <div class="flex mb-3 items-center">
      <time></time>
      <p class="text-mercury text-8 leading-140 font-normal">{{ playTime }}</p>

      <div class="w-full h-3px rounded-8 mx-2 cursor-pointer relative">
        <progress ref="progressBar" value="0" min="0"></progress>
        <input
          ref="seek"
          value="0"
          min="0"
          type="range"
          step="1"
          class="seek"
          @input="skipAhead"
          @mousemove="updateSeekTooltip"
        />
        <div ref="tooltip" class="seek-tooltip">00:00</div>
      </div>

      <p class="text-mercury text-8 leading-140 font-normal">
        {{ durationTime }}
      </p>
    </div>

    <div class="flex w-full justify-between">
      <button
        class="bg-grayscale-16 rounded-full w-8 h-8 flex items-center justify-center"
        @click="play"
      >
        <img
          v-if="playing"
          src="@/assets/icons/recorder/pause.svg"
          alt="pause"
          class="w-3 h-3"
        />
        <img
          v-else
          src="@/assets/icons/recorder/play.svg"
          alt="play"
          class="w-3 h-3"
        />
      </button>
      <button
        v-if="showRemove"
        class="bg-grayscale-16 rounded-full w-8 h-8 flex items-center justify-center"
        :disabled="loading"
        @click="remove"
      >
        <img
          src="@/assets/icons/recorder/close.svg"
          alt="close"
          class="w-4 h-4"
        />
      </button>
      <button
        v-if="showDownload"
        class="bg-grayscale-16 rounded-full w-8 h-8 flex items-center justify-center"
        :disabled="loading"
        @click="download"
      >
        <img
          src="@/assets/icons/recorder/download.svg"
          alt="download"
          class="w-4 h-4"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import downloadjs from "downloadjs";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showRemove: {
    type: Boolean,
    default: false,
  },
  showDownload: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onVideoRemove"]);

const reviewVideo = ref<HTMLVideoElement>();
const progressBar = ref<any>();
const tooltip = ref<any>();
const seek = ref<any>();

const playing = ref(false);

const playTime = ref("00:00");
const durationTime = ref("00:00");

onMounted(() => {
  if (reviewVideo.value) {
    reviewVideo.value.src = props.url;
    reviewVideo.value.currentTime = 0.1;
  }
});

const onPlay = () => {
  playing.value = true;
};

const onPause = () => {
  playing.value = false;
};

const onEnd = () => {
  reviewVideo.value!.currentTime = 0;
};

const play = () => {
  if (playing.value) {
    return reviewVideo.value!.pause();
  }

  reviewVideo.value!.play();
};

const remove = () => {
  emit("onVideoRemove");
};

const download = () => {
  downloadjs(props.url);
};

const onTimeUpdate = () => {
  const videoCurrentTime = Math.round(reviewVideo.value?.currentTime || 0);
  const time = formatTime(videoCurrentTime);
  playTime.value = `${time.minutes}:${time.seconds}`;

  updateProgress();
};

const updateProgress = () => {
  const currentTime = reviewVideo.value?.currentTime || 0;

  if (seek.value) {
    seek.value.value = Math.floor(currentTime);
  }
  progressBar.value.value = Math.floor(currentTime);
};

const onLoadedMetadata = async () => {
  let videoDuration = Math.round(reviewVideo.value!.duration);

  if (videoDuration === Infinity) {
    while (videoDuration === Infinity) {
      await new Promise((r) => setTimeout(r, 50));
      reviewVideo.value!.currentTime = 100;
      videoDuration = Math.round(reviewVideo.value!.duration);
    }

    reviewVideo.value!.currentTime = 0;
  }

  if (videoDuration == Infinity) {
    reviewVideo.value!.currentTime = 1e101;
    reviewVideo.value!.ontimeupdate = function () {
      this.ontimeupdate = () => {
        return;
      };
      reviewVideo.value!.currentTime = 0;
      return;
    };
  }

  const time = formatTime(videoDuration);
  durationTime.value = `${time.minutes}:${time.seconds}`;

  seek.value.setAttribute("max", videoDuration);
  progressBar.value.setAttribute("max", videoDuration);
};

const formatTime = (seconds: number) => {
  const max = reviewVideo.value?.duration || 0;
  const min = 0;

  let calculatedSeconds = Math.max(min, seconds);
  calculatedSeconds = Math.min(max, seconds);

  const minutes = Math.floor(calculatedSeconds / 60);
  const secondsLeft = seconds % 60;

  return {
    minutes: minutes < 10 ? "0" + minutes : minutes,
    seconds: secondsLeft < 10 ? "0" + secondsLeft : secondsLeft,
  };
};

const updateSeekTooltip = (event: any) => {
  const skipTo = Math.round(
    (event.offsetX / event.target.clientWidth) *
      parseInt(event.target.getAttribute("max"), 10),
  );
  seek.value.setAttribute("data-seek", skipTo);
  const t = formatTime(skipTo);
  if (tooltip.value?.textContent) {
    tooltip.value.textContent = `${t.minutes}:${t.seconds}`;
  }
  const rect = reviewVideo.value!.getBoundingClientRect();
  tooltip.value.style.left = `${event.pageX - rect.left}px`;
};

// skipAhead jumps to a different point in the video when
// the progress bar is clicked
const skipAhead = (event: any) => {
  const skipTo = event.target.dataset.seek
    ? event.target.dataset.seek
    : event.target.value;
  reviewVideo.value!.currentTime = skipTo;
  progressBar.value.value = skipTo;
  seek.value.value = skipTo;
};
</script>

<style scoped>
#reviewVideo {
  transform: scaleX(-1);
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 100%;
  height: 3px;
  pointer-events: none;
  position: absolute;
  top: 0;
}

progress::-webkit-progress-bar {
  background-color: rgba(234, 234, 234, 0.2);
  border-radius: 8px;
}

progress::-webkit-progress-value {
  background: #f5f5f5;
  border-radius: 8px;
}

progress::-moz-progress-bar {
  background: #f5f5f5;
}

.seek {
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  height: 3px;
  opacity: 0;
}

.seek:hover + .seek-tooltip {
  display: block;
}

.seek-tooltip {
  display: none;
  position: absolute;
  top: -20px;
  pointer-events: none;
  margin-left: -62px;
  font-size: 8px;
  padding: 2px;
  content: attr(data-title);
  font-weight: bold;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
}

.seek::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

.seek::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

.seek:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.seek::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 1.3px;
}

.seek::-moz-range-thumb {
  background: transparent;
  cursor: pointer;
}

.seek:focus::-moz-range-track {
  outline: none;
}
</style>
