import FeedbackService from "@/services/feedback-service";
import UploadService from "@/services/upload-service";
import { Feedback } from "@/types/feedback";
import { UploadScope } from "@/types/upload";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";
import { parseMediaURL } from "@/utils/aws";

interface FeedbackStore {
  feedbackByRequestId: Record<string, Feedback>;
  recording: boolean;
  recordingTime: string;
  byRequestId: Record<
    string,
    Feedback & { recordedFile: File | null; recordedFileUrl: string }
  >;
}

const uploadService = new UploadService("uploads");
const feedbackService = new FeedbackService("feedback");

export const useFeedbackStore = defineStore("feedback", {
  state: (): FeedbackStore => ({
    feedbackByRequestId: {},
    recording: false,
    recordingTime: "00:00",
    byRequestId: {},
  }),
  actions: {
    setRecording(v: boolean) {
      this.recording = v;
    },
    setRecordingTime(v: string) {
      this.recordingTime = v;
    },
    saveRecordedFile(orderId: string, file: File | null) {
      this.byRequestId[orderId] = {
        ...(this.byRequestId[orderId] || {}),
        recordedFile: file,
        recordedFileUrl: file ? URL.createObjectURL(file) : "",
      };
    },

    async uploadRecordedFile(orderId: string) {
      const recordedFile = this.byRequestId[orderId]?.recordedFile;

      if (!recordedFile) {
        return;
      }

      try {
        return await uploadService.uploadFile({
          file: recordedFile,
          name: recordedFile.name,
          scope: UploadScope.proFeedback,
          owner: "",
        });
      } catch (error) {
        throw handleError(error);
      }
    },

    fillFeedback(orderId: string, feedback: Partial<Feedback>) {
      this.byRequestId[orderId] = {
        ...(this.byRequestId[orderId] || {}),
        ...feedback,
      };
    },

    async getFeedback(orderId: string) {
      if (!this.feedbackByRequestId[orderId]) {
        this.feedbackByRequestId[orderId] = await feedbackService.getFeedback(
          orderId,
        );
      }

      return this.feedbackByRequestId[orderId];
    },
    async sendFeedback(orderId: string, productId: string, userId: string) {
      const localFeedback = this.byRequestId[orderId] || {};
      const uploadedFile = await this.uploadRecordedFile(orderId);
      const feedback = await feedbackService.sendFeedback({
        orderId,
        textFeedback: localFeedback.textFeedback || "",
        suggestConsultationCall: localFeedback.suggestConsultationCall || false,
        productId: productId,
        userId: userId,
        mediaUrl: parseMediaURL(uploadedFile?.url),
      });

      return feedback;
    },
  },
});
