import { Feedback } from "@/types/feedback";
import { Track } from "@/types/track";
import { handleError } from "@/utils/error";
import BaseService from "./base-service";

class FeedbackService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getFeedback(orderId: string): Promise<Feedback> {
    try {
      return await this.get(`${this.getServiceContext}?orderId=${orderId}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async sendFeedback(
    feedback: Feedback & { orderId: string },
  ): Promise<Track | void> {
    try {
      return await this.post(`${this.getServiceContext}`, feedback);
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default FeedbackService;
