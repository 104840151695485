import { Product, ProductNameByType, ProductType } from "./product";
import { User } from "./user";

export enum FeedbackTabsTypes {
  all = "all",
  archived = "archived",
}

export enum FeedbackRequestType {
  marketing = "marketing",
  career = "career",
  technical = "technical",
  production = "production",
}

export enum FeedbackRequestTitleByType {
  marketing = "Marketing",
  career = "Career",
  technical = "Technical",
  production = "Production",
}

export enum FeedbackQuestionsByType {
  marketing = "What have you done to market this song already? If nothing, do you have any ideas or plans?",
  career = "What short term goals are you trying to accomplish?",
  technical = "What part of the song do you need the most help with?",
  production = "What part of the song do you need the most help with?",
}

export interface FeedbackRequestFilters {
  types: ProductType[];
}

export interface Feedback {
  _id?: string;

  productId: string;
  product?: Product;

  proId?: string;
  pro?: User;

  userId: string;
  user?: User;

  mediaUrl?: string;
  textFeedback?: string;
  suggestConsultationCall: boolean;
}

export const feedbackTabsItems = [
  {
    type: FeedbackTabsTypes.all,
    label: "All",
    visibleTypes: [ProductType.video, ProductType.audio, ProductType.written],
  },
  {
    type: FeedbackTabsTypes.archived,
    label: "Archived",
    visibleTypes: [ProductType.video, ProductType.audio, ProductType.written],
  },
];
